import Template from '../Template'
import * as style from './style.module.scss'
import Link from 'gatsby-link'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Helmet } from 'react-helmet'

const PageAbout = () => {
  return (
    <Template>
      <div className={style.pageWrapper}>
        <Helmet>
          <title>About | doggos.com</title>
        </Helmet>
        <div className={style.heroContainer}>
          <h1>About us</h1>
          <p className={style.subline}>
            Doggos.com is the fastest-growing platform for finding a pet care
            professional near you
          </p>
        </div>
        <div className={style.image}>
          <div className={style.copy}>
            <h2>How it started</h2>
            <p>
              We have been publishing <strong>FREE</strong> educational
              resources for dog owners since 2017 (you may have read some of{' '}
              <Link to='/blog-posts'>our articles! 🎉 🦮</Link>)
            </p>
          </div>
          <div>
            <StaticImage
              src={'./1.jpg'}
              alt='Image of a black German Shepherd'
              className={style.image}
              placeholder='blurred'
            />
          </div>
        </div>
        <div className={style.content}>
          <h2>Today</h2>
          <p>
            Since then, our very small, US-based, team saw an opportunity to
            provide more features for pet care professionals, like the ability
            to chat and book online with local businesses, payment protection,
            transaction history, dog profiles, and more. We continue to expand
            our testing to new cities, and hope to welcome you as a member of
            our community very soon!
          </p>
          <p>
            Right now, we're hard at work building new features and working with
            our partners to tailor our solution to the real needs pet owners and
            businesses face today, starting with the high cost of pet software,
            and bad design.
          </p>

          <h3>Contact us</h3>

          <p>
            Questions about this website or any of its material? Please reach
            out{' '}
            <a
              title='Email support@doggos.com'
              href='mailto:support@doggos.com'
            >
              via email
            </a>{' '}
            anytime and someone will get back to you as soon as possible.
          </p>
          <p className={style.note}>
            This website participates in the Amazon affiliates program, meaning
            we may earn money when you click links to amazon.com
          </p>
        </div>
      </div>
    </Template>
  )
}

export default PageAbout
